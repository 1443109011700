.ReactModal__Overlay {
	@apply bg-transparent;
	@apply fixed;
	@apply top-0;
	@apply bottom-0;
	@apply left-0;
	@apply right-0;
	z-index: 2048;

	@apply transition-all;
	@apply ease-in-out;
	@apply duration-300;

	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	pointer-events: none;
}

.ReactModal__Overlay--after-open {
	pointer-events: all;
	@apply bg-dark/60 backdrop-blur-sm;
}

.ReactModal__Overlay--before-close {
	@apply bg-transparent backdrop-blur-0;
}

body.ReactModal__Body--open {
	@apply fixed inset-0 w-full overflow-hidden touch-none;
	-webkit-overflow-scrolling: auto;
}

.ReactModal__Content {
	outline: none !important;
	@apply absolute;
	@apply bg-dark;
	@apply max-w-lg;
	@apply top-1/2 left-1/2;
	width: 90%;

	@apply rounded-lg;
	@apply border border-gray/40;
	@apply -translate-y-1/2 -translate-x-1/2;
	@apply transition;
	@apply ease-in-out;
	@apply duration-300;
	@apply flex flex-col;
	@apply shadow-2xl;
}

.ReactModal__Content.type-big {
	@apply max-w-6xl;
}

/* From middle (default) */
/* Initial state */
.ReactModal__Content {
	@apply scale-50;
	@apply opacity-0;
}

/* Open animation */
.ReactModal__Content--after-open {
	@apply scale-100;
	@apply opacity-100;
}

/* Close animation */
.ReactModal__Content--before-close {
	@apply scale-50;
	@apply opacity-0;
}

/* From bottom slide in */
/* Initial state */
.ReactModal__Content.fromBottom {
	@apply scale-100 duration-500;
	@apply translate-y-[100vh];
}

/* Open animation */
.ReactModal__Content--after-open.fromBottom {
	@apply -translate-y-1/2;
}

/* Close animation */
.ReactModal__Content--before-close.fromBottom {
	@apply translate-y-[100vh];
}